"use client";
import { getMask, useImageExtraContext } from "@/hooks/useImageExtraContext";
import { cn } from "@/lib/utils";
import { BASE_URL } from "@/utils/APIRoutes";
import { BoundingBoxFromAPI } from "@/utils/APIRouteTypes";
import { Properties } from "@/utils/properties";
import Image from "next/image";
import React from "react";
import TShirt from "../others/tshirt";

type AggregatedImageProps = {
  side: "front" | "back";
  imgSrc: string;
  boundingBox: BoundingBoxFromAPI;
  imgHeight: number
  imgWidth: number
  tshirtAltText: string
  onClick?: () => void;
  className?: string
};

export default function AggregatedImage(props: AggregatedImageProps) {
  const tshirtRef = React.useRef<HTMLDivElement>(null);
  const { ImgContext, blendMode, filter } = useImageExtraContext();
  const imgSrc = props.imgSrc.startsWith('/') ? BASE_URL + props.imgSrc : props.imgSrc
  const mask = getMask(props.side, ImgContext)

  const contextKey1 = props.side === "front" ? "frontImages" : "backImages";

  const handleClick = () => {
    if (props.onClick)
      props.onClick()
  }

  const handleImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    if (e.currentTarget.naturalWidth > e.currentTarget.naturalHeight) {
      e.currentTarget.style.width = "100% !important";
      e.currentTarget.style.minWidth = "100%";
      e.currentTarget.style.height = "auto";
      e.currentTarget.style.aspectRatio = `${e.currentTarget.naturalWidth} / ${e.currentTarget.naturalHeight}`;
    }
    else {
      e.currentTarget.style.height = "100% !important";
      e.currentTarget.style.minHeight = "100%";
      e.currentTarget.style.width = "auto";
      e.currentTarget.style.aspectRatio = `${e.currentTarget.naturalWidth} / ${e.currentTarget.naturalHeight}`;
    }
  }

  return (
    <div className={cn("relative flex size-full items-center justify-center bg-white", props.className)}>
      <div ref={tshirtRef} className="relative aggregated-image w-full" onClick={handleClick} data-track="Aggregated Image" style={{ aspectRatio: `${props.imgWidth} / ${props.imgHeight}` }}>
        <div
          className={
            `group absolute overflow-hidden border-dashed border-green-400 transition-all duration-75 hover:border active:border ${blendMode}`
          }
          style={{
            top: `${props.boundingBox.y * 100}%`,
            left: `${props.boundingBox.x * 100}%`,
            width: `${props.boundingBox.width * 100}%`,
            height: `${props.boundingBox.height * 100}%`,
            maskImage: mask,
            maskSize: '100% 100%',
          }}
        >
          <table className="pointer-events-none absolute z-40 size-full opacity-0 group-hover:opacity-50 group-active:opacity-50 ">
            <tbody>
              <tr>
                <td className="border-b border-r"></td>
                <td className="border-b"></td>
              </tr>
              <tr>
                <td className="border-r"></td>
                <td></td>
              </tr>
            </tbody>
          </table>
          {ImgContext[contextKey1].map((img, index) => {
            const boundingBoxRealHeight = props.boundingBox.height * props.imgHeight
            const boundingBoxRealWidth = props.boundingBox.width * props.imgWidth
            const ImgWidth = `${img.scale * Properties.defaultImgWidth}%`;
            const ImgHeight = `${img.scale * Properties.defaultImgWidth * boundingBoxRealWidth / boundingBoxRealHeight}%`;
            const ImgTop = `${img.y * 100}%`;
            const ImgLeft = `${img.x * 100}%`;

            return (
              <div
                className="absolute z-30 flex items-center justify-center overflow-hidden object-contain transition-all "
                style={{
                  width: ImgWidth,
                  top: ImgTop,
                  left: ImgLeft,
                  height: ImgHeight,
                  maskImage: mask,
                  maskSize: 'cover',
                }}
                key={index}
              >
                {img.src.length > 0 && <Image
                  src={img.src}
                  style={{
                    maskImage: mask,
                    maskSize: '100% 100%',
                    filter: filter,

                  }}
                  alt="Generated Image"
                  className='max-h-full max-w-full opacity-90 !w-auto !h-auto'
                  loading="eager"
                  key={index}
                  layout="responsive"
                  draggable={false}
                  width={200}
                  onLoad={handleImageLoad}
                  height={0}
                  onContextMenu={(e) => e.preventDefault()}
                />
                }
              </div>
            );
          })}
        </div>
        <TShirt src={imgSrc} altText={props.tshirtAltText} height={props.imgHeight} width={props.imgWidth} />
      </div>
    </div>
  );
}
