import { useCustomImageContext } from "@/contexts/CustomImageContext"
import { useNonUrlContext } from "@/contexts/NonUrlContext";
import { ColorType } from "@/utils/APIRouteTypes";
import { CustomImageContextType, ImageMode } from "@/types/contextTypes";
import { useSearchParams } from "next/navigation";
import { useMemo } from "react"

export const useImageExtraContext = () => {
    const [customImageContext, setCustomImageContext] = useCustomImageContext()
    const searchParams = useSearchParams();
    const NonUrlContext = useNonUrlContext()
    const blendModeFromSearchParams = searchParams.get("blendMode");

    const blendMode = useMemo(() => {
        if (blendModeFromSearchParams)
            return blendModeFromSearchParams
        if (NonUrlContext.colorMode === ColorType.BRIGHT)
            return "mix-blend-hard-light";
        if (NonUrlContext.colorMode === ColorType.DARK)
            return "mix-blend-lighten";
        if (NonUrlContext.colorMode === ColorType.LIGHT)
            return "mix-blend-darken";

        return ''
    }, [NonUrlContext.colorMode]);

    const filter = useMemo(() => {
        switch (customImageContext.imageMode) {
            case ImageMode.NORMAL:
                return 'contrast(2) brightness(1) '

            case ImageMode.BLACK_AND_WHITE:
                return 'grayscale(2) brightness(0.9) contrast(100) brightness(10)'

            case ImageMode.BW_INVERT:
                return 'grayscale(2)  brightness(2) invert(100%) contrast(100) brightness(10)'

            case ImageMode.DARK_BW:
                return 'grayscale(2)  brightness(4) contrast(100) brightness(10)'
        }
    }, [customImageContext.imageMode])

    return {
        ImgContext: customImageContext,
        setImgContext: setCustomImageContext,
        blendMode: blendMode,
        filter: filter
    }
}


export const getMask = (side: 'front' | 'back', customImageContext: CustomImageContextType) => {
    if (side === "front" && customImageContext.frontMask) {
        return `url(${customImageContext.frontMask})`;
    } else if (side === "back" && customImageContext.backMask) {
        return `url(${customImageContext.backMask})`;
    }
    return "none";
}