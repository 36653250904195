export enum ImageMode {
    BLACK_AND_WHITE = "greyscale",
    NORMAL = "normal",
    BW_INVERT = "bw_invert",
    DARK_BW = "dark",
}

export type ImageType = {
    src: string;
    x: number;
    y: number;
    scale: number;
};

export type CustomImageContextType = {
    frontImages: ImageType[];
    backImages: ImageType[];
    imageMode: ImageMode;
    frontMask: string | null;
    backMask: string | null;
};
