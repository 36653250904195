"use client";
import { getMask, useImageExtraContext } from "@/hooks/useImageExtraContext";
import { BoundingBoxFromAPI } from "@/utils/APIRouteTypes";
import { cn } from "@/utils/cn";
import { Properties } from "@/utils/properties";
import { XIcon } from "lucide-react";
import Image from "next/image";
import { useSearchParams } from "next/navigation";
import React, { useEffect } from "react";

type AggregatedImageProps = {
    boundingBox: BoundingBoxFromAPI | null
    cameraBox?: BoundingBoxFromAPI | null
    isCircular: boolean
    small?: boolean
    onClick?: () => void
};

export default function AggregatedImage2(props: AggregatedImageProps) {
    const { ImgContext, blendMode, filter } = useImageExtraContext();
    const searchParams = useSearchParams();
    const ref = React.useRef<HTMLDivElement>(null);
    const mask = getMask('front', ImgContext)

    const color = searchParams.get('color') || 'black'
    const contextKey1 = "frontImages"

    const [bounds, setBounds] = React.useState({
        x: 0,
        y: 0,
        width: 0,
        height: 0,
    });


    const onTshirtLoaded = () => {
        if (ref.current && bounds.width === 0)
            setBounds(ref.current.getBoundingClientRect());
    }

    useEffect(() => {
        onTshirtLoaded()
    }, [])

    const handleClick = () => {
        if (props.onClick)
            props.onClick()
    }

    if (props.boundingBox === null)
        return <></>

    const handleImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
        if (e.currentTarget.naturalWidth > e.currentTarget.naturalHeight) {
            e.currentTarget.style.width = "100% !important";
            e.currentTarget.style.minWidth = "100%";
            e.currentTarget.style.height = "auto";
            e.currentTarget.style.aspectRatio = `${e.currentTarget.naturalWidth} / ${e.currentTarget.naturalHeight}`;
        }
        else {
            e.currentTarget.style.height = "100% !important";
            e.currentTarget.style.minHeight = "100%";
            e.currentTarget.style.width = "auto";
            e.currentTarget.style.aspectRatio = `${e.currentTarget.naturalWidth} / ${e.currentTarget.naturalHeight}`;
        }
    }

    return (
        <div className="relative flex h-full w-full items-center aggregated-image justify-center bg-[#c3c3c3]" onClick={handleClick} data-track="Aggregated Image 2">

            <div className={cn(" bg-gradient-to-br bg-[#c3c3c3] flex items-center justify-center", {
                "w-[200px] h-[400px]": !props.small,
                "w-[100px] h-[200px]": props.small
            })} ref={ref}>
                <div
                    className={
                        cn(`group relative overflow-hidden w-[95%] shadow-xl bg-red-400`,
                            {
                                'rounded-xl': props.cameraBox && !props.isCircular,
                                'rounded-sm': !props.cameraBox && !props.isCircular,
                                'rounded-full': props.isCircular,
                            }
                        )
                    }

                    style={{
                        aspectRatio: `${props.boundingBox.width} / ${props.boundingBox.height}`,
                        backgroundColor: color,
                    }}

                >
                    {props.cameraBox && <div className="absolute z-[100] bg-slate-50 rounded-xl border-2 border-black flex items-center justify-center overflow-hidden" style={{
                        top: `${100 * props.cameraBox.y * props.boundingBox.width / props.boundingBox.height}%`,
                        left: `${props.cameraBox.x * 100}%`,
                        width: `${props.cameraBox.width * 100}%`,
                        aspectRatio: `${props.cameraBox.width * 100} / ${props.cameraBox.height * 100}`,
                    }}>
                        <XIcon className="absolute h-[200%] w-[200%] cursor-pointer text-red-200 -z-10" />
                        <span className="text-xs text-center">
                            Camera
                        </span>
                        {/* <Image
                            alt="Phone Camera"
                            src={props.sizeImage}
                            width={100}
                            height={0}
                            style={{
                                top: "5px",
                                left: "5px",
                            }}
                        /> */}

                    </div>}
                    <table className="pointer-events-none absolute z-50 h-full w-full opacity-0 group-hover:opacity-50 group-active:opacity-50 ">
                        <tbody>
                            <tr>
                                <td className="border-b border-r"></td>
                                <td className="border-b"></td>
                            </tr>
                            <tr>
                                <td className="border-r"></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                    {ImgContext[contextKey1].map((img, index) => {
                        const boundingBoxRealHeight = (props.boundingBox?.height || 1)
                        const boundingBoxRealWidth = (props.boundingBox?.width || 1)
                        const ImgWidth = `${img.scale * Properties.defaultImgWidth}%`
                        const ImgHeight = `${img.scale * Properties.defaultImgWidth * boundingBoxRealWidth / boundingBoxRealHeight}%`;
                        const ImgTop = `${img.y * 100}%`
                        const ImgLeft = `${img.x * 100}%`

                        return (
                            <div
                                className="absolute z-30 object-contain flex items-center justify-center overflow-hidden shadow-lg transition-all"
                                style={{
                                    width: ImgWidth,
                                    top: ImgTop,
                                    height: ImgHeight,
                                    left: ImgLeft,
                                    maskImage: mask,
                                    maskSize: 'cover',
                                }}
                                draggable={false}
                                key={index}
                            >
                                {img.src.length > 0 && <Image
                                    src={img.src}
                                    style={{
                                        maskImage: mask,
                                        maskSize: '100% 100%',
                                        filter: filter
                                    }}
                                    alt="Generated Image"
                                    className='opacity-90 max-w-full max-h-full !w-auto !h-auto'
                                    layout="responsive"
                                    key={index}
                                    width={200}
                                    onLoad={handleImageLoad}
                                    height={0}
                                    draggable={false}
                                />
                                }
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
