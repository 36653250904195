export const BASE_URL = process.env.NODE_ENV !== "development" ? "https://api.flashyfox.in" : "http://localhost:8000"
// export const BASE_URL = "https://api.flashyfox.in"
export const APIRoutes = {
    PRODUCT_LIST: "/api/products?category=:search",
    PRODUCT_DETAILS: "/api/products/:id/",
    SAMPLE_IMAGES: "/api/sampleimages/",
    PAY_INITIATE: '/pay/',
    PAY_UPDATE_CHECKOUT_DETAILS: '/pay/update-details/',
    PAY_CALLBACK: '/pay/callback/',
    PAY_CHECK_STATUS: '/pay/check-status/',
    GET_PAYMENT_OBJECT: '/pay/object-details/:id',
    MAKE_PREDICTION: '/predictions/',
    GET_PREDICTION: '/predictions/:id',
    UPLOAD_DESIGN: '/predictions/upload/',
    ADD_DESIGNER: "/predictions/designer/add/",
    LOGIN: "/api/login/",
    SEND_FEEDBACK: "/api/submitfeedback/",
    CATALOG_IMAGES: "/api/catalogitems/",
    UNSUBSCRIBE_EMAIL: "/comms/unsubscribe-email/",
    GET_MY_DESIGNS: "/predictions/userdesigns/",
    WISHLIST: "/api/wishlist/",
    CART_LIST: "/api/cart/",
    CART_UPDATE: "/api/cart/:id/",
}

export const makeURL = (route: string, params: Record<string, string | number>) => {
    let url = route
    for (const [key, value] of Object.entries(params)) {
        url = url.replace(`:${key}`, value.toString())
    }
    return url
}